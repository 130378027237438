import React, { useRef, useState } from 'react';
import { useIsCard, useIsMobile } from '../../Hooks/Client';

import BusinessCardPreview from '../../Components/BusinessCardPreview';
import BusinessCardPreviewFront from '../../Components/BusinessCardPreviewFront';
import CardDataEdit from '../../Components/CardDataEdit';
import Confirm from '../../Components/Confirm';
import InstruccionesPaper from '../../Components/InstruccionesPaper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const BusinessCardContainer = ({ download }) => {
	const isMobile = useIsMobile();
	const isConfirm = useSelector((state) => state.picture.confirm);
	const ref = useRef(null);
	const ref2 = useRef(null);
	const [data, setData] = useState(false);
	const {
		tarj_fiat,
		tarj_peugeot,
		tarj_nissan,
		tarj_volkswagen,
		tarj_jeep,
		tarj_multimarca,
		tarj_corporate,
		tarj_usados,
	} = useIsCard();

	const empresa = tarj_fiat
		? 'fiat'
		: tarj_peugeot
		? 'peugeot'
		: tarj_nissan
		? 'nissan'
		: tarj_volkswagen
		? 'volkswagen'
		: tarj_jeep
		? 'jeep'
		: tarj_multimarca
		? 'multimarca'
		: tarj_corporate
		? 'corporate'
		: tarj_usados
		? '_usados'
		: null;

	return (
		<>
			{isConfirm ? (
				<Confirm downloadName='Tarjeta Personal' />
			) : (
				<>
					<Container
						tarj_fiat={tarj_fiat}
						tarj_peugeot={tarj_peugeot}
						tarj_nissan={tarj_nissan}
						tarj_volkswagen={tarj_volkswagen}
						tarj_jeep={tarj_jeep}
						tarj_multimarca={tarj_multimarca}
						tarj_corporate={tarj_corporate}
						tarj_usados={tarj_usados}
						isMobile={isMobile}>
						<InstruccionesPaper name='tarjeta' />
						<CardDataEdit
							reference={ref}
							reference2={ref2}
							setData={setData}
							data={data}
						/>
						<PreviewSide isMobile={isMobile}>
							<BusinessCardPreviewFront
								reference={ref}
								data={data}
								empresa={empresa}
							/>
							{empresa && (
								<BusinessCardPreview
									reference={ref2}
									data={data}
									empresa={empresa}
								/>
							)}
						</PreviewSide>
					</Container>
				</>
			)}
		</>
	);
};

export default BusinessCardContainer;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
	align-items: center;
	justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
	padding: ${({ isMobile }) =>
		isMobile ? '3.5rem 0 10rem 0' : '5rem 0 4rem 0'};
	flex-wrap: wrap;

	${({ isMobile }) => isMobile && 'height: 200vh'};
`;
const PreviewSide = styled.div`
	${({ isMobile }) => isMobile && 'width: 80%'};
	${({ isMobile }) => isMobile && 'display: flex'};
	${({ isMobile }) => isMobile && 'flex-direction:column'};
	${({ isMobile }) => isMobile && 'gap: 15px'};
`;
