import React from 'react';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/Client';

const SignRenaultPreview = ({ reference, data }) => {
	const mobile = useIsMobile();
	return (
		<Container mobile={mobile} ref={reference}>
			<CompanyContainer mobile={mobile}>
				<Img mobile={mobile} src='./imgs/logoRenault.png' alt='' />
			</CompanyContainer>
			<DataContainer mobile={mobile}>
				<Text mobile={mobile} dataEdit={true}>
					{!data.profileName ? (
						<span className='regular'>Nombre Apellido</span>
					) : (
						<span className='regular'> {data.profileName}</span>
					)}
					{!data.position ? (
						<span className='regular'>Puesto</span>
					) : (
						<span className='regular'> {data.position}</span>
					)}
					{!data.phone ? (
						<span className='regular'>Teléfono</span>
					) : (
						<span className='regular'> {data.phone}</span>
					)}
					{!data.email ? (
						<span className='regular'>ejemplo@ejemplo.com</span>
					) : (
						<span className='regular'> {data.email}</span>
					)}
					<span className='regular'>AUTOCITY | M. TAGLE (H) y CIA SACIF</span>
					{!data.adress ? (
						<span className='regular'>Ejemplo de dirección</span>
					) : (
						<span className='regular'> {data.adress}</span>
					)}
				</Text>
			</DataContainer>
		</Container>
	);
};

export default SignRenaultPreview;

const Container = styled.div`
	max-width: 460px;
	width: ${({ mobile }) => (mobile ? '90%' : '460px')};
	min-width: ${({ mobile }) => (mobile ? '90%' : '460px')};
	height: ${({ mobile }) => (mobile ? '115px' : '135px')};
	min-height: ${({ mobile }) => (mobile ? '115px' : '135px')};
	display: flex;
	margin: ${({ mobile }) => (mobile ? 'auto' : '5rem 0 5rem 1.3rem')};
	overflow: hidden;
`;
const CompanyContainer = styled.div`
	width: 25%;
	min-width: 25%;
	max-width: 25%;
	height: 100%;
	background-color: ${theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
`;
const DataContainer = styled.div`
	width: 75%;
	max-width: 75%;
	height: 100%;
	background-color: ${theme.colors.white};
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const Text = styled.p`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: ${({ dataEdit }) => (dataEdit ? 'flex-start' : 'center')};
	color: ${theme.colors.black};
	font-size: ${({ dataEdit, mobile }) =>
		mobile ? (dataEdit ? '8px' : '5px') : dataEdit ? '0.8rem' : '0.49rem'};

	span:nth-child(1) {
		text-transform: uppercase;
		font-weight: bold;
	}

	span:nth-child(2) {
		text-transform: uppercase;
	}
`;

const Img = styled.img`
	${({ mobile }) => (mobile ? 'width:50px' : 'width: 50px')};
`;
