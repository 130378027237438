import { Link, NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import CardSubMenu from '../CardSubMenu';
import { brands } from '../../../Utils';
import { cleanState } from '../../../Store/Actions/downloadPicture.actions';
import styled from 'styled-components';
import theme from '../../../Constants/theme';
import { useDispatch } from 'react-redux';

const LogoMobile = ({ onClickHandler, menuOpen }) => {
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const [cardMenuOpen, setCardMenuOpen] = useState(false);
	const handleCardMenu = () => setCardMenuOpen(!cardMenuOpen);

	const handleClick = () => {
		onClickHandler();

		dispatch(cleanState());
	};
	const handleMenu = () => setSubMenuOpen(!subMenuOpen);
	return (
		<>
			<Container>
				<img src='./imgs/logo.png' alt='' />
				{!menuOpen ? (
					<img src='./imgs/burgerIcon.png' alt='' onClick={handleClick} />
				) : (
					<img src='./imgs/mobileCloseIcon.png' alt='' onClick={handleClick} />
				)}
			</Container>
			{menuOpen && (
				<LinkContainer>
					<Link className='regular menuLink' to='/' onClick={handleClick}>
						Crear foto de perfil
					</Link>
					<MenuButton className='regular subMenuLink' onClick={handleCardMenu}>
						Crear tarjeta personal
						{cardMenuOpen ? (
							<RiArrowUpSLine size={24} style={{ marginLeft: 5 }} />
						) : (
							<RiArrowDownSLine size={24} style={{ marginLeft: 5 }} />
						)}
					</MenuButton>
					{cardMenuOpen && (
						<CardSubMenu
							className='regular subMenuLink'
							isOpen={cardMenuOpen}
							onClick={handleCardMenu}
						/>
					)}
					<MenuButton onClick={handleMenu} className='regular'>
						Crear firma de mail{' '}
						{subMenuOpen ? (
							<RiArrowUpSLine size={24} style={{ marginLeft: 5 }} />
						) : (
							<RiArrowDownSLine size={24} style={{ marginLeft: 5 }} />
						)}
					</MenuButton>
					{subMenuOpen && (
						<SubMenuContainer>
							{brands.map((elem, index) => (
								<NavLink
									className='regular subMenuLink'
									key={index}
									onClick={handleClick}
									to={`/firma${elem.path}`}>
									{elem.name}
								</NavLink>
							))}
						</SubMenuContainer>
					)}
				</LinkContainer>
			)}
		</>
	);
};

export default LogoMobile;

const Container = styled.div`
	/* width: 100%; */
	background-color: ${theme.colors.black};
	padding: 15px 22px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
`;
const LinkContainer = styled.div`
	width: 100%;
	top: 70px;
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: ${theme.colors.signBlack};
	border: none;
	padding: 42px 0 0 0;
	height: 88vh;
	z-index: 2;
	.menuLink {
		color: ${theme.colors.white};
		text-decoration: none;
		font-weight: 500;
		font-size: 18px;
		line-height: 125%;
		padding: 0 0 18px 0;
		margin: 0 21px 0 21px;
		border-bottom: 1px solid ${theme.colors.white};
	}
`;
const SubMenuContainer = styled.div`
	background-color: transparent;
	margin: 0 0 18px 21px;
	padding: 23px 0 0 0;
	.subMenuLink {
		color: ${theme.colors.white};
		text-decoration: none;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		flex-direction: column;
		margin: 28px 0 0 17px;
	}
	.subMenuLink:nth-child(1) {
		margin: 0 0 0 17px;
	}
`;
const MenuButton = styled.button`
	height: auto;
	color: ${theme.colors.white};
	background-color: transparent;
	border: none;
	text-align: left;
	font-weight: 500;
	font-size: 18px;
	line-height: 125%;
	padding: 0 0 0 0;
	margin: 18px 21px 0 21px;
	display: flex;
	align-items: center;
`;
