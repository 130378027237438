import { downloadPicture } from '../Types/downloadPicture.types';

const { CREATE_FILE, CREATE_FILE_2, CLEAN_SCREEN } = downloadPicture;

const initialState = {
	picture: {},
	picture2: {},
	confirm: false,
};

const DownloadPicture = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_FILE:
			return {
				...state,
				picture: {
					file: action.file,
					pictureType: action.pictureType,
					name: action.name,
				},
				confirm: true,
			};
		case CREATE_FILE_2:
			return {
				...state,
				picture2: {
					file: action.file,
					pictureType: action.pictureType,
					name: action.name,
				},
			};
		case CLEAN_SCREEN:
			return { picture: {}, confirm: false, picture2: {} };
		default:
			return state;
	}
};

export default DownloadPicture;
