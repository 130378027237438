export const isDisabled = (data, fields) => {
	const arr = Object.values(data);
	let isInputEmpty = true;

	if (fields === arr.length) {
		isInputEmpty = arr.includes('');
	}

	return isInputEmpty ? true : false;
};

export const brands = [
	{ name: 'Genérica', path: '_generica', fields: '2' },
	{ name: 'Tagle', path: '_renault', fields: '5' },
	{ name: 'Rolen', path: '_volkswagen', fields: '2' },
	{ name: 'Usados', path: '_usados', fields: '2' },
];

export const cardBrands = [
	{ id: '1', order: 1, name: 'Autocity', path: '',
		submenu: [
			{ id: '11', order: 1, name: 'Fiat', path: '_fiat'},
			{ id: '12', order: 2, name: 'Peugeot', path: '_peugeot'},
			{ id: '13', order: 3, name: 'Nissan', path: '_nissan'},
			{ id: '14', order: 4, name: 'Volkswagen', path: '_volkswagen'},
			{ id: '15', order: 5, name: 'Jeep', path: '_jeep'},
			{ id: '16', order: 6, name: 'Multimarca', path: '_multimarca'},
		],
	},
	{ id: '2', order: 2, name: 'Autocity Corporate', path: '_corporate' },
	{ id: '3', order: 3, name: 'Autocity Usados', path: '__usados' },
];

export const puestosPerfil = [
	{ name: 'Administrativo' },
	{ name: 'Administrativo Comercial de Postventa' },
	{ name: 'Administrativo Comercial de Seguros' },
	{ name: 'Administrativo de Accesorios' },
	{ name: 'Administrativo de Entregas' },
	{ name: 'Administrativo de Logística' },
	{ name: 'Administrativo de Licitaciones y Adjudicaciones' },
	{ name: 'Administrativo de Plan de Ahorro' },
	{ name: 'Administrativo de Postventa' },
	{ name: 'Administrativo de Repuestos' },
	{ name: 'Administrativo de Suscripciones' },
	{ name: 'Administrativo de Ventas' },
	{ name: 'Alistador 0km' },
	{ name: 'Agente de Contact Center' },
	{ name: 'Analista' },
	{ name: 'Analista de Administración y Finanzas' },
	{ name: 'Analista de Calidad' },
	{ name: 'Analista de Capacitación y Desarrollo' },
	{ name: 'Analista de Compras' },
	{ name: 'Analista de CRM Automation' },
	{ name: 'Analista de Cuentas Corrientes' },
	{ name: 'Analista de Diseño Grafico - Marketing' },
	{ name: 'Analista Funcional' },
	{ name: 'Analista de Liquidación de Sueldos' },
	{ name: 'Analista de Pago a proveedores' },
	{ name: 'Analista de Paid Media - Marketing' },
	{ name: 'Analista de Planeamiento y reporting' },
	{ name: 'Analista de Recursos' },
	{ name: 'Analista Trade y Eventos - Marketing' },
	{ name: 'Analista en CRM Automation Y Email Marketing' },
	{ name: 'Analista en Visualización de Datos y Reportología - Marketing' },
	{ name: 'Analista VOC' },
	{ name: 'Analista Web - Marketing' },
	{ name: 'Asofix' },
	{ name: 'Asesor Comercial' },
	{ name: 'Asesora Comercial' },
	{ name: 'Asesor Comercial Corporativo' },
	{ name: 'Asesora Comercial Corporativo' },
	{ name: 'Asesor Comercial de Seguros' },
	{ name: 'Asesora Comercial de Seguros' },
	{ name: 'Asesor Comercial Multimarca' },
	{ name: 'Asesora Comercial Multimarca' },
	{ name: 'Asesor Comercial Plan de Ahorro' },
	{ name: 'Asesora Comercial Plan de Ahorro' },
	{ name: 'Asesor de Accesorios' },
	{ name: 'Asesor de Entregas' },
	{ name: 'Asesora de Entregas' },
	{ name: 'Asesor de Repuestos' },
	{ name: 'Asesor de Servicio' },
	{ name: 'Asesor de Siniestros' },
	{ name: 'Asesor Comercial Canal Indirecto' },
	{ name: 'Asesora Comercial Canal Indirecto' },
	{ name: 'Asistente de Entregas' },
	{ name: 'Auditor' },
	{ name: 'Auditor de Contact Center' },
	{ name: 'Auditor Interno' },
	{ name: 'Brand Manager' },
	{ name: 'Calidad' },
	{ name: 'Cadete' },
	{ name: 'Cajera' },
	{ name: 'Cajero' },
	{ name: 'Contact Center' },
	{ name: 'Créditos' },
	{ name: 'Desarrollador' },
	{ name: 'Director Comercial' },
	{ name: 'Directora Comercial' },
	{ name: 'Director de Administracion' },
	{ name: 'Director de Operaciones' },
	{ name: 'Ejecutivo Venta Empresas' },
	{ name: 'Ejecutiva Venta Empresas' },
	{ name: 'Especialista en Compensaciones y Beneficios' },
	{ name: 'Garantista' },
	{ name: 'Gerente Comercial' },
	{ name: 'Gerente Comercial Canal Corporativo y Multimarca' },
	{ name: 'Gerente Comercial Canal Indirecto' },
	{ name: 'Gerente Comercial Carmuv' },
	{ name: 'Gerente Comercial Plan de ahorro' },
	{ name: 'Gerente Comercial Usados' },
	{ name: 'Gerente de Administracion' },
	{ name: 'Gerente de Administracion de Plan de Ahorro' },
	{ name: 'Gerente de Agentes' },
	{ name: 'Gerenta de Agentes' },
	{ name: 'Gerente de Calidad' },
	{ name: 'Gerente de Logística y Siniestros' },
	{ name: 'Gerente de Marketing' },
	{ name: 'Gerente de Negocio Usados' },
	{ name: 'Gerente de Post Venta' },
	{ name: 'Gerente de Recursos Humanos' },
	{ name: 'Gerente de Siniestros' },
	{ name: 'Gerente de Ventas Corporativo y Multimarca' },
	{ name: 'Gerente de Venta de Usados Interior' },
	{ name: 'Gerente de Ventas de Plan de Ahorro' },
	{ name: 'Gerente de Ventas de Plan de Ahorro' },
	{ name: 'Gerente de Ventas de 0km y Planes Interior' },
	{ name: 'Gerente de Ventas 0km y Planes' },
	{ name: 'Gerenta de Ventas 0km y Planes' },
	{ name: 'Gestor' },
	{ name: 'Jefe Comercial canal indirecto' },
	{ name: 'Jefa Comercial canal indirecto' },
	{ name: 'Jefe Comercial Corporativo' },
	{ name: 'Jefe Comercial de Accesorios' },
	{ name: 'Jefe Comercial de Seguros' },
	{ name: 'Jefa Comercial de Seguros' },
	{ name: 'Jefe Comercial de Sucursal' },
	{ name: 'Jefa Comercial de Sucursal' },
	{ name: 'Jefe de Accesorios' },
	{ name: 'Jefa de Accesorios' },
	{ name: 'Jefe de Administración' },
	{ name: 'Jefe de Administración Comercial' },
	{ name: 'Jefe de Administración Comercial Usados - Mayorista' },
	{ name: 'Jefe de Administración de Plan de Ahorro' },
	{ name: 'Jefe de Administración de RRHH y Liquidación de Sueldos' },
	{ name: 'Jefe de Agentes' },
	{ name: 'Jefe de Alistaje' },
	{ name: 'Jefe de Aplicaciones de Negocio' },
	{ name: 'Jefe de Calidad' },
	{ name: 'Jefa de Calidad' },
	{ name: 'Jefe de Control de Gestión de Planes de Ahorro' },
	{ name: 'Jefe de Experiencia Al Cliente' },
	{ name: 'Jefe de Experiencia Al Cliente Stellantis' },
	{ name: 'Jefe de Finanzas' },
	{ name: 'Jefe de Logística' },
	{ name: 'Jefe de Mantenimiento' },
	{ name: 'Jefe de Planeamiento y Reporting' },
	{ name: 'Jefe de Postventa' },
	{ name: 'Jefe de Reportes' },
	{ name: 'Jefe de Repuestos' },
	{ name: 'Jefe de Seguros' },
	{ name: 'Jefa de Seguros' },
	{ name: 'Jefe de Seguridad' },
	{ name: 'Jefe de Sucursal' },
	{ name: 'Jefa de Sucursal' },
	{ name: 'Jefe de Servicio' },
	{ name: 'Jefe de Siniestros' },
	{ name: 'Jefe de Ventas de Plan de Ahorro' },
	{ name: 'Jefe de Ventas de Usados' },
	{ name: 'Jefe de Ventas de Usados' },
	{ name: 'Jefa de Ventas de Usados' },
	{ name: 'Jefe de Ventas Web Usados' },
	{ name: 'Jefe de Ventas Corporativas' },
	{ name: 'Jefe de Ventas 0km y Planes Interior' },
	{ name: 'Jefa de Ventas 0km y Planes Interior' },
	{ name: 'Lead Manager' },
	{ name: 'Legales' },
	{ name: 'Líder de Contenidos - Marketing' },
	{ name: 'Líder de Adquisicion - Marketing' },
	{ name: 'Líder de Trade y Eventos - Marketing' },
	{ name: 'Logística de Repuestos' },
	{ name: 'Mantenimiento' },
	{ name: 'Marketing' },
	{ name: 'Operaciones' },
	{ name: 'Pasante' },
	{ name: 'Recepción' },
	{ name: 'Recepcionista' },
	{ name: 'Receptor depósito 0km' },
	{ name: 'Recursos Humanos' },
	{ name: 'Reporting' },
	{ name: 'Responsable de Calidad' },
	{ name: 'Responsable de Depósito' },
	{ name: 'Responsable de Llamadas' },
	{ name: 'Responsable de Marketing' },
	{ name: 'Seguridad' },
	{ name: 'Sistemas' },
	{ name: 'Supervisor Comercial Plan de ahorro' },
	{ name: 'Supervisora Comercial Plan de ahorro' },
	{ name: 'Supervisor de Administración' },
	{ name: 'Supervisor de Administración Comercial' },
	{ name: 'Supervisor de Administración de Ventas' },
	{ name: 'Supervisor de Administración de Plan de Ahorro' },
	{ name: 'Supervisor de Administración y Finanzas' },
	{ name: 'Supervisor de Administración de Ventas' },
	{ name: 'Supervisor de Accesorios' },
	{ name: 'Supervisor de Alistaje' },
	{ name: 'Supervisor de Calidad' },
	{ name: 'Supervisora de Calidad' },
	{ name: 'Supervisor de Contact Center' },
	{ name: 'Supervisor de Compra de Plan de Ahorro' },
	{ name: 'Supervisor de Entregas' },
	{ name: 'Supervisor de Experiencia Al Cliente' },
	{ name: 'Supervisora de Experiencia Al Cliente' },
	{ name: 'Supervisor de Garantías' },
	{ name: 'Supervisora de Garantías' },
	{ name: 'Supervisor de Gestoría' },
	{ name: 'Supervisor de Logística' },
	{ name: 'Supervisor de Plan de Ahorro' },
	{ name: 'Supervisora de Plan de Ahorro' },
	{ name: 'Supervisor de Postventa' },
	{ name: 'Supervisor de Postventa - Renault Minuto' },
	{ name: 'Supervisor de Recursos Humanos' },
	{ name: 'Supervisor de Repuestos' },
	{ name: 'Supervisor de Servicios' },
	{ name: 'Supervisor de Siniestros' },
	{ name: 'Supervisora de Taller de Carrocería y Proveedores' },
	{ name: 'Supervisora de Tesorería' },
	{ name: 'Supervisor de Trade y Eventos' },
	{ name: 'Supervisor de Ventas Web de Usados' },
	{ name: 'Supervisora de Ventas Web de Usados' },
	{ name: 'Usados' },
	{ name: 'Vendedor Corporativo y Multimarca' },
	{ name: 'Vendedora Corporativo y Multimarca' },
	{ name: 'Vendedor de Accesorios' },
	{ name: 'Vendedora de Accesorios' },
	{ name: 'Vendedor de Plan de Ahorro' },
	{ name: 'Vendedora de Plan de Ahorro' },
	{ name: 'Vendedor de Seguros' },
	{ name: 'Vendedora de Seguros' },
	{ name: 'Vendedor de Siniestros' },
	{ name: 'Vendedor de Usados' },
	{ name: 'Vendedora de Usados' },
	{ name: 'Vendedor de 0km y Planes' },
	{ name: 'Vendedora de 0km y Planes' },
	{ name: 'Venta de Agentes' },
];

export const puestosUsados = [
	{ name: 'Asesor de Entregas' },
	{ name: 'Asesora de Entregas' },
	{ name: 'Gerente de Negocio Usados' },
	{ name: 'Gerente de Ventas de Usados Interior' },
	{ name: 'Jefe de ventas usados' },
	{ name: 'Jefa de ventas usados' },
	{ name: 'Jefe de Ventas Web Usados' },
	{ name: 'Supervisor de Ventas Web de Usados' },
	{ name: 'Supervisora de Ventas Web de Usados' },
	{ name: 'Vendedor de usados' },
	{ name: 'Vendedora de usados' },
];

export const puestosMarcas = [
	{ name: 'Analista de Calidad' },
	{ name: 'Asesor de Entregas' },
	{ name: 'Asesora de Entregas' },
	{ name: 'Director Comercial' },
	{ name: 'Gerente de Agentes' },
	{ name: 'Gerenta de Agentes' },
	{ name: 'Gerente de Ventas Corporativo y Multimarca' },
	{ name: 'Gerente de Ventas de Plan de Ahorro' },
	{ name: 'Gerente de Ventas de Plan de Ahorro' },
	{ name: 'Gerente de Ventas de 0km y Planes Interior' },
	{ name: 'Gerente de Ventas 0km y Planes' },
	{ name: 'Gerenta de Ventas 0km y Planes' },
	{ name: 'Jefe de Agentes' },
	{ name: 'Jefa de Agentes' },
	{ name: 'Jefe de Sucursal' },
	{ name: 'Jefa de Sucursal' },
	{ name: 'Jefe de Ventas 0km y Planes Interior' },
	{ name: 'Jefa de Ventas 0km y Planes Interior' },
	{ name: 'Supervisor de Plan de Ahorro' },
	{ name: 'Supervisora de Plan de Ahorro' },
	{ name: 'Vendedor Corporativo y Multimarca' },
	{ name: 'Vendedora Corporativo y Multimarca' },
	{ name: 'Vendedor de Plan de Ahorro' },
	{ name: 'Vendedora de Plan de Ahorro' },
	{ name: 'Vendedor de 0km y Planes' },
	{ name: 'Vendedora de 0km y Planes' },
	{ name: 'Venta de Agentes' },
];
