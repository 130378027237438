import React from 'react';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { useIsMobile } from '../../Hooks/Client';

const InstruccionesPaper = ({
	name,
	margin,
	marginMobile,
	marginTop,
	marginTopMobile,
}) => {
	const isMobile = useIsMobile();

	const desktopStyles = {
		padding: '1rem',
		fontSize: 18,
		color: '#007C8F',
		width: 230,
	};
	const mobileStyles = {
		padding: '0.875rem',
		fontSize: 15,
		color: '#007C8F',
		width: 260,
	};
	return (
		<Container margin={margin} marginMobile={marginMobile} isMobile={isMobile}>
			<Paper
				className='regular'
				sx={isMobile ? mobileStyles : desktopStyles}
				elevation={3}>
				Completá los datos y {!isMobile && <br />}
				descargá tu {name}
			</Paper>
			<Img
				marginTop={marginTop}
				marginTopMobile={marginTopMobile}
				isMobile={isMobile}
				src={isMobile ? './imgs/mobileArrow.png' : './imgs/arrow.png'}
				alt=''
			/>
		</Container>
	);
};

export default InstruccionesPaper;

const Container = styled.div`
	width: ${({ isMobile }) => (isMobile ? '290px' : '280px')};
	display: flex;
	flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'column')};
	${({ isMobile }) => isMobile && 'align-items: flex-start'};
	margin: ${({ isMobile, margin, marginMobile }) =>
		isMobile
			? marginMobile
				? marginMobile
				: '15px 0 0 0'
			: margin
			? margin
			: '0 1rem 8rem 0'};
`;

const Img = styled.img`
	width: ${({ isMobile }) => (isMobile ? '43px' : '49px')};
	margin-top: ${({ isMobile, marginTop, marginTopMobile }) =>
		isMobile
			? marginTopMobile
				? marginTopMobile
				: '1.25rem'
			: marginTop
			? marginTop
			: '0.5rem'};
	align-self: flex-end;
`;
