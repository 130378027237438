import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/Client';

const SignPreview = ({ reference, data, empresa }) => {
	const mobile = useIsMobile();
	const [website, setWebsite] = useState('www.autocity.com.ar');

	useEffect(() => {
		switch (empresa) {
		  case 'rolen':
			setWebsite('www.rolen.com.ar\nAv. Santa Ana 6363');
			break;
		  default:
			setWebsite('www.autocity.com.ar');
			break;
		}
	  }, [empresa]);

	return (
		<Container mobile={mobile} ref={reference}>
			<CompanyContainer mobile={mobile}>
				<LogoContainer mobile={mobile}>
					<Img
						mobile={mobile}
						src={
							empresa === 'generica'
								? './imgs/logo.png'
								: empresa === 'rolen'
								? './imgs/logoRolen.png'
								: empresa === 'usados' && './imgs/logoUsados.png'
						}
						alt=''
						empresa={empresa}
					/>
				</LogoContainer>
				<TextContainer mobile={mobile}>
					<Text mobile={mobile} className='regular'>
						<span>0810 888 4000</span>
						{website.split('\n').map((web) => (
							<>
							<p>{web}</p>
							</>
						))}
					</Text>
				</TextContainer>
			</CompanyContainer>
			<DataContainer mobile={mobile}>
				<Text mobile={mobile} dataEdit={true}>
					{!data.profileName ? (
						<span className='regular'>Nombre Apellido</span>
					) : (
						<span className='regular'> {data.profileName}</span>
					)}
					{!data.position ? (
						<span className='thin'>Puesto</span>
					) : (
						<span className='thin'> {data.position}</span>
					)}
				</Text>
			</DataContainer>
		</Container>
	);
};

export default SignPreview;

const Container = styled.div`
	max-width: 620px;
	width: ${({ mobile }) => (mobile ? '90%' : '620px')};
	min-width: ${({ mobile }) => (mobile ? '90%' : '620px')};

	height: ${({ mobile }) => (mobile ? '53px' : '100px')};
	min-height: ${({ mobile }) => (mobile ? '53px' : '100px')};
	display: flex;
	margin: ${({ mobile }) =>
		mobile ? '3rem auto 3rem auto' : '5rem 0 5rem 1.3rem'};
	overflow: hidden;
`;
const CompanyContainer = styled.div`
	width: 44%;
	min-width: 44%;
	height: 100%;
	background-color: ${theme.colors.black};
	display: flex;
	align-items: center;
`;
const DataContainer = styled.div`
	width: 56%;
	max-width: 56%;
	height: 100%;
	background-color: ${theme.colors.signBlack};
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0 0 0.75rem;
`;

const LogoContainer = styled.div`
	width: 58%;
	max-width: 58%;
	height: ${({ mobile }) => (mobile ? '33px' : '60px')};
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid ${theme.colors.white};
	margin: auto 0;
`;
const TextContainer = styled.div`
	width: 42%;
`;

const Text = styled.p`
	width: 100%;
	display: flex;
	font-size:10px;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: ${({ dataEdit }) => (dataEdit ? 'flex-start' : 'center')};
	color: ${theme.colors.white};
	span:nth-child(1) {
		font-size: ${({ dataEdit, mobile }) =>
			mobile ? (dataEdit ? '15px' : '5px') : dataEdit ? '1.5rem' : '0.61rem'};
	}
	span:nth-child(2) {
		font-size: ${({ dataEdit, mobile }) =>
			mobile ? (dataEdit ? '8px' : '5px') : dataEdit ? '0.9rem' : '0.49rem'};
	}
`;

const Img = styled.img`
	${({ mobile, empresa }) =>
		!mobile
			? (empresa === 'rolen' || empresa === 'usados') && 'width:110px'
			: empresa === 'rolen' || empresa === 'usados'
			? 'width: 52.71px'
			: 'width: 60.71px'};
`;
