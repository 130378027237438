import React from 'react';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/Client';

const BusinessCardPreviewFront = ({ reference, data, empresa }) => {
  const mobile = useIsMobile();

  const getLogoSrc = () => {
    if (empresa === '_usados') {
      return './imgs/usados_logo.png';
    } else if (empresa === 'corporate') {
      return './imgs/corporate_logo.png';
    } else if (empresa === 'multimarca') {
      return './imgs/multimarcas_logo.png';
    } 
    return './imgs/autocity_logo.png';
  };

  const logoSrc = getLogoSrc();

  return (
    <Container mobile={mobile} ref={reference}>
      <LogoContainer mobile={mobile}>
        <Img mobile={mobile} src={logoSrc} alt='' empresa={empresa} />
      </LogoContainer>
    </Container>
  );
};

export default BusinessCardPreviewFront;

const Container = styled.div`
  max-width: 460px;
  width: ${({ mobile }) => (mobile ? '90%' : '400px')};
  min-width: ${({ mobile }) => (mobile ? '90%' : '400px')};
  height: ${({ mobile }) => (mobile ? '159px' : '209px')};
  min-height: ${({ mobile }) => (mobile ? '159px' : '209px')};
  display: flex;
  margin: ${({ mobile }) => (mobile ? 'auto' : '3rem 0 3rem 1.3rem')};
  background-color: ${theme.colors.black};
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;
const LogoContainer = styled.div`
  width: 58%;
  max-width: 58%;
  height: ${({ mobile }) => (mobile ? '33px' : '60px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
`;

const Img = styled.img`
width: ${({ mobile, empresa }) => (empresa === 'multimarca' || empresa === 'corporate' ? (mobile ? '180px' : '240px') : '110px')};
`;
