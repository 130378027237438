import React from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../Hooks/Client';
import { BsWhatsapp } from 'react-icons/bs';

const ProfilePreview = ({ reference, data }) => {
	const isMobile = useIsMobile();
	const { phone, profileName, image, position } = data;
	const displayImage = image && URL.createObjectURL(image);

	return (
		<EmailContainer className='preview' isMobile={isMobile}>
			<Downloaded id='download' ref={reference} isMobile={isMobile}>
				<ImageContainer
					// BaW={BaW}
					background={displayImage}>
					{image === null && <NoImage src='./imgs/noImage.png' alt='' />}
				</ImageContainer>
				<InformationContainer isMobile={isMobile}>
					<DataContainer>
						<Name isMobile={isMobile} className='regular'>
							{profileName ? profileName : 'Nombre Apellido'}
						</Name>{' '}
						<Position isMobile={isMobile} className='regular'>
							{position ? position : ''}
						</Position>
						{phone && (
						<Phone isMobile={isMobile} className='regular'>
							{phone ? (
								<span>
									<BsWhatsapp
										size={isMobile ? 13 : 20}
										style={{ marginRight: 10 }}
									/>
									{phone}
								</span>
							) : (
								<span>
									<BsWhatsapp
										size={isMobile ? 13 : 20}
										style={{ marginRight: 10 }}
									/>
									Teléfono
								</span>
							)}
						</Phone>
							)}
					</DataContainer>
					<CompanyContainer>
						<Company
							isMobile={isMobile}
							src='./imgs/Autocity.png'
							alt='Logo Compañía'
						/>
					</CompanyContainer>
				</InformationContainer>
			</Downloaded>
			{/* <FormGroup sx={{ display: 'none' }}>
<FormControlLabel
  className='regular'
  control={<Switch />}
  label='Foto blanco y negro'
  name='byn'
  onChange={(e) =>
    handleInputChange(e.target.value, e.target.name, e.target.checked)
  }
/>
</FormGroup> */}
		</EmailContainer>
	);
};

export default ProfilePreview;

const EmailContainer = styled.div`
	background-color: white;
	border: 1px solid #efefef;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	width: ${({ isMobile }) => (isMobile ? '330px' : '481px')};
	min-height: ${({ isMobile }) => (isMobile ? null : '524px')};
	min-width: ${({ isMobile }) => (isMobile ? null : '481px')};
	height: ${({ isMobile }) => (isMobile ? '374px' : '524px')};
	margin: ${({ isMobile }) =>
		isMobile ? '0 auto 3rem auto' : '3.5rem 0 3.5rem 3rem'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.MuiFormGroup-root {
		align-self: flex-start;
		${({ isMobile }) =>
			isMobile ? 'margin: 1rem 0 22px 1.5rem' : 'margin: 1rem 0 0 1.5rem'};
	}
	padding: 13px 0;
`;

const Downloaded = styled.div`
	width: ${({ isMobile }) => (isMobile ? '90%' : '90%')};
	height: ${({ isMobile }) => (isMobile ? '80%' : '82.5%')};
	display: flex;
	flex-direction: column;
	-webkit-display: flex;
	-webkit-flex-direction: column;
`;

const ImageContainer = styled.div`
	width: 100%;
	height: 72%;
	${({ background }) =>
		!background
			? 'background-color: lightGrey'
			: `background-image: url('${background}')`};
	background-size: cover;
	background-position: center;
	${({ BaW }) =>
		BaW && '-webkit-filter: grayscale(100%);  filter: grayscale(100%);'}
	display: flex;
	flex-direction: row;
	align-items: ${({ background }) => (!background ? 'center' : 'flex-end')};
	justify-content: center;
	column-gap: 20px;
	-webkit-display: flex;
	-webkit-flex-direction: row;
	-webkit-align-items: ${({ background }) =>
		!background ? 'center' : 'flex-end'};
	-webkit-justify-content: center;
	-webkit-column-gap: 20px;
`;

const NoImage = styled.img`
	width: 75px;
`;

const InformationContainer = styled.div`
	width: 100%;
	flex: 1;
	padding: ${({ isMobile }) => (isMobile ? '5px 0 10px 0' : '0.5rem 0 10px 0')};
	background-color: black;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	-webkit-display: flex;
	-webkit-flex-direction: row;
	-webkit-justify-content: center;
	-webkit-align-items: center;
`;
const DataContainer = styled.div`
	width: 60%;
	margin-left: ${({ isMobile }) => (isMobile ? null : '1.5rem')};
`;
const CompanyContainer = styled.div`
	width: 40%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-end;
	-webkit-flex-direction: row;
	-webkit-justify-content: center;
	-webkit-align-items: flex-end;
`;
const Name = styled.div`
	font-size: ${({ isMobile }) => (isMobile ? '1.1875rem' : '1.75rem')};
	line-height: ${({ isMobile }) => (isMobile ? '1.75rem' : '2.62rem')};
	color: white;
`;
const Position = styled.div`
	font-size: ${({ isMobile }) => (isMobile ? '0.75rem' : '1.125rem;')};
	line-height: ${({ isMobile }) => (isMobile ? '1.125rem' : '1.67rem')};
	color: white;
`;

const Phone = styled.div`
	font-size: ${({ isMobile }) => (isMobile ? '0.75rem' : '1.125rem;')};
	line-height: ${({ isMobile }) => (isMobile ? '1.125rem' : '1.67rem')};
	color: white;
	span {
		display: flex;
		align-items: center;
		-webkit-display: flex;
		-webkit-align-items: center;
	}
`;

const Company = styled.img`
	height: ${({ isMobile }) => (isMobile ? '32%' : '39px;')};
	/*margin-bottom: 0.5rem; */
`;
