import { downloadPicture } from '../Types/downloadPicture.types';
import html2canvas from 'html2canvas';

const { CREATE_FILE, CLEAN_SCREEN, CREATE_FILE_2 } = downloadPicture;

export const createFile = (descarga, mobile, location, descarga2) => {
	return async (dispatch) => {
		const name = location.pathname;
		
		// const downloadSize =
		// 	name.includes('generica') || name.includes('firma_volkswagen')
		// 		? mobile
		// 			? { width: 330, height: 53 }
		// 			: { width: 620, height: 100 }
		// 		: name.includes('renault')
		// 		? mobile
		// 			? { width: 460, height: 135 }
		// 			: { width: 460, height: 135 }
		// 		: name.includes('tarj_fiat') || name.includes('tarj_peugeot') || name.includes('tarj_nissan') || name.includes('tarj_volkswagen') || name.includes('tarj_jeep') || name.includes('tarj_multimarca') || name.includes('tarj_corporate') || name.includes('tarj_usados')
		// 		? mobile
		// 			? {  width: 88.9, height: 50.8  }
		// 			: {  width: 88.9, height: 50.8  }
		// 		: mobile
		// 		? { width: 297, height: 297 }
		// 		: { width: 432, height: 432 }

		html2canvas(descarga, { scale: 1 })
			.then((dataUrl) => {
				dispatch({
					type: CREATE_FILE,
					file: dataUrl,
					pictureType: name,
					name: 'file1',
				});
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {});

		if (descarga2) {
			html2canvas(descarga2, { scale: 1 })
				.then((dataUrl) => {
					dispatch({
						type: CREATE_FILE_2,
						file: dataUrl,
						pictureType: name,
						name: 'file2',
					});
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {});
		}
	};
};

export const cleanState = () => {
	return async (dispatch) => {
		await dispatch({ type: CLEAN_SCREEN });
	};
};
