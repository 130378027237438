import React, { useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import CardSubMenu from './CardSubMenu';
import { Link } from 'react-router-dom';
import LogoMobile from './LogoMobile';
import SubMenu from './SubMenu';
import { cleanState } from '../../Store/Actions/downloadPicture.actions';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useIsMobile } from '../../Hooks/Client';

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const handleMenu = () => setMenuOpen(!menuOpen);
	const [cardMenuOpen, setCardMenuOpen] = useState(false);
	const handleCardMenu = () => setCardMenuOpen(!cardMenuOpen);
	const mobile = useIsMobile();
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(cleanState());
		setTimeout(() => {
			window.location.reload();
		}, 100);
	};
	return (
		<>
			{mobile ? (
				<LogoMobile onClickHandler={handleMenu} menuOpen={menuOpen} />
			) : (
				<Container>
					<DataContainer>
						<Img src='./imgs/logo.png' alt='' />
						<LinkContainer>
							<Link className='regular menuLink' to='' onClick={handleClick}>
								Crear foto de perfil
							</Link>
							<CardMenuContainer isMobile={mobile}>
								<MenuButton className='regular' onClick={handleCardMenu}>
									Crear tarjeta personal
									{cardMenuOpen ? (
										<RiArrowUpSLine size={24} style={{ marginLeft: 5 }} />
									) : (
										<RiArrowDownSLine size={24} style={{ marginLeft: 5 }} />
									)}
								</MenuButton>
								{cardMenuOpen && (
									<CardSubMenu isOpen={cardMenuOpen} onClick={handleCardMenu} />
								)}
							</CardMenuContainer>
							<MenuButton onClick={handleMenu} className='regular'>
								Crear firma de mail
								{menuOpen ? (
									<>
										<RiArrowUpSLine size={24} style={{ marginLeft: 5 }} />{' '}
										<SubMenu onClick={handleClick} />
									</>
								) : (
									<RiArrowDownSLine size={24} style={{ marginLeft: 5 }} />
								)}
							</MenuButton>
						</LinkContainer>
					</DataContainer>
				</Container>
			)}
		</>
	);
};

export default Header;

const Container = styled.div`
	margin: auto auto;
	width: 100%;
	padding: 1.5rem 0;
	background-color: black;
`;
const DataContainer = styled.div`
	max-width: 1440px;
	width: 95%;
	margin: auto auto;
	display: flex;
	justify-content: space-between;
`;
const Img = styled.img`
	margin-left: ${({ mobile }) => (mobile ? null : '86px')};
	width: 110px;
	background: transparent;
`;

const LinkContainer = styled.div`
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;

	.menuLink {
		color: white;
		text-decoration: unset;
		font-size: 1rem;
		line-height: 1.25rem;
	}
`;

const MenuButton = styled.button`
	cursor: pointer;
	color: white;
	text-decoration: unset;
	background-color: unset;
	font-size: 1rem;
	line-height: 1.25rem;
	display: flex;
	align-items: center;
	border: none;
`;
const CardMenuContainer = styled.div`
	${({ isMobile }) => isMobile && 'display: flex'};
	${({ isMobile }) => isMobile && 'flex-direction: column'};
`;
