import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useIsMobile } from '../../Hooks/Client';
import { useSelector } from 'react-redux';

const Confirm = ({ downloadName }) => {
	const [downloadOk, setDownloadOk] = useState(false);
	const mobile = useIsMobile();
	const image = useSelector((state) => state.picture?.picture?.file);
	const image2 = useSelector((state) => state.picture?.picture2?.file);

	console.log(image, image2);

	useEffect(() => {
		if (image) {
			const contenedor = document.getElementById('contenedor1');
			contenedor.appendChild(image);
		}
	}, [image]);

	useEffect(() => {
		if (image2) {
			const contenedor2 = document.getElementById('contenedor2');
			contenedor2.appendChild(image2);
		}
	}, [image2]);

	const handleDownload = (image, image2) => {
		const link = document.createElement('a');
		link.download = 'my-image-name.png';
		link.href = image.toDataURL();
		link.click();

		const link2 = document.createElement('a');
		link2.download = 'my-image-name.png';
		link2.href = image2.toDataURL();
		link2.click();

		setDownloadOk(true);
	};

	return (
		<Container id='holis' mobile={mobile}>
			<DownloadContainer mobile={mobile}>
				{!downloadOk ? (
					<Text mobile={mobile} className='medium'>
						Tu {downloadName} se generó con éxito
					</Text>
				) : (
					<Text mobile={mobile} className='medium'>
						Tu {downloadName} fue descargada con éxito
					</Text>
				)}
				{!downloadOk ? (
					<Button
						mobile={mobile}
						className='medium'
						name='download'
						onClick={() => handleDownload(image, image2)}>
						Descargar
					</Button>
				) : (
					<Button
						mobile={mobile}
						className='medium'
						name='goBack'
						onClick={() => window.location.reload()}>
						Volver a generar
					</Button>
				)}
			</DownloadContainer>
			<div id='contenedor2'></div>
			<div id='contenedor1'></div>
		</Container>
	);
};

export default Confirm;

const Container = styled.div`
	width: 100%;
	height: ${({ mobile }) => (mobile ? '100vh' : '70vh')};
	display: flex;
	flex-direction: ${({ mobile }) =>
		mobile ? 'column-reverse' : 'row-reverse'};
	align-items: center;
	justify-content: center;
	/* padding-top: ${({ mobile }) => !mobile && '10rem'}; */
`;

const DownloadContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ mobile }) => (mobile ? 'margin: 3rem 0 0 0' : 'margin:0 0 0 3.5rem')};
`;
const Text = styled.p`
	font-size: 1.25rem;
	line-height: 1.5rem;
	${({ mobile }) => mobile && 'text-align: center;'};
`;
const Button = styled.button`
	margin-top: 1rem;
	border: none;
	background-color: #007c8f;
	font-size: 1.125rem;
	line-height: 1.7rem;
	padding: 0.65rem 1rem;
	color: #ffffff;
	border-radius: 5px;
	cursor: pointer;
`;
