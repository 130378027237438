import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { brands } from '../../../Utils';

const SubMenu = (props) => {
	const handleClick = () => props.onClick();
	return (
		<Container>
			{brands.map((elem, index) => (
				<NavLink
					className='regular subMenuLink'
					key={index}
					to={`/firma${elem.path}`}
					onClick={handleClick}>
					{elem.name}
				</NavLink>
			))}
		</Container>
	);
};

export default SubMenu;

const Container = styled.div`
	position: absolute;
	background-color: white;
	width: 14%;
	top: 65px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	padding: 0.5rem 0px;
	.subMenuLink {
		text-decoration: none;
		padding: 0.5rem 1.5rem;
		font-weight: 300;
		font-size: 1rem;
		line-height: 1.5rem;
		color: #575757;
	}
`;
