import React from 'react';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/Client';

const BusinessCardPreview = ({ reference, data, empresa }) => {
	const mobile = useIsMobile();
	const getLogoSrc = () => {
		if (empresa === 'fiat') {
			return './imgs/fiat_logo.png';
		} else if (empresa === 'peugeot') {
			return './imgs/peugeot_logo.png';
		} else if (empresa === 'nissan') {
			return './imgs/nissan_logo.png';
		} else if (empresa === 'volkswagen') {
			return './imgs/volkswagen_logo.png';
		} else if (empresa === 'jeep') {
			return './imgs/jeep_logo.png';
		} else if (empresa === 'renault') {
			return './imgs/renault_logo.png';
		}
		return '';
	};
	const logoSrc = getLogoSrc();
	return (
		<Container mobile={mobile} ref={reference}>
			<CompanyContainer mobile={mobile}>
				<Img mobile={mobile} src={logoSrc} alt='' />
			</CompanyContainer>
			<DataContainer mobile={mobile}>
				<Text mobile={mobile} dataEdit={true}>
					{!data.position ? (
						<span className='regular'>Puesto</span>
					) : (
						<span className='regular'> {data.position}</span>
					)}
					{!data.profileName ? (
						<span className='regular'>Nombre Apellido</span>
					) : (
						<span className='regular'> {data.profileName}</span>
					)}
					{!data.phone ? (
						<span className='regular'>Teléfono</span>
					) : (
						<span className='regular'> {data.phone}</span>
					)}
					{!data.email ? (
						<span className='regular'>ejemplo@ejemplo.com</span>
					) : (
						<span className='regular'> {data.email}</span>
					)}
				</Text>
			</DataContainer>
			<ContactContainer mobile={mobile}>
				<ContactText mobile={mobile} className='regular'>
					{' '}
					0810-888-4000 | autocity.com.ar
				</ContactText>
				<ContactText mobile={mobile} className='regular'>
					<img src='./imgs/fb_icon.png' alt='Icon' />
					<img src='./imgs/ig_icon.png' alt='Icon' />
					autocityok
				</ContactText>
			</ContactContainer>
		</Container>
	);
};

export default BusinessCardPreview;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '90%' : '400px')};
	min-width: ${({ mobile }) => (mobile ? '90%' : '400px')};
	height: ${({ mobile }) => (mobile ? '159px' : '209px')};
	min-height: ${({ mobile }) => (mobile ? '159px' : '209px')};
	max-width: 460px;
	display: flex;
	margin: ${({ mobile }) => (mobile ? 'auto' : '3rem 0 3rem 1.3rem')};
	flex-direction: column;
	overflow: hidden;
	background-color: ${theme.colors.white};
`;
const CompanyContainer = styled.div`
	width: 100%;
	height: auto;
	background-color: ${theme.colors.white};
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	position: relative;
	top: 0;
`;
const DataContainer = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.white};
	display: flex;
	flex-direction: column;
`;
const ContactContainer = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.white};
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin: ${({ mobile }) =>
		mobile ? '15px auto auto auto' : '35px 15px 17px 0px'};
`;
const ContactText = styled.span`
	font-size: ${({ mobile }) => (mobile ? '8px' : '13px')};
	font-weight: lighter;
	img {
		width: 13px;
		height: 13px;
		margin-right: 5px;
	}
`;
const Text = styled.p`
	width: 100%;
	display: flex;
	margin-left: 15%;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	color: ${theme.colors.black};
	font-size: ${({ dataEdit, mobile }) =>
		mobile ? (dataEdit ? '8px' : '5px') : dataEdit ? '0.8rem' : '0.49rem'};
`;
const Img = styled.img`
	margin: 15px;
	width: ${({ mobile }) => (mobile ? '100px' : '100px')};
`;
