import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import DataEdit from '../../Components/DataEdit';

import InstruccionesPaper from '../../Components/InstruccionesPaper';
import { useIsMobile, useIsSign } from '../../Hooks/Client';
import SignPreview from '../../Components/SignPreview';
import { useSelector } from 'react-redux';
import Confirm from '../../Components/Confirm';
import SignRenaultPreview from '../../Components/SignRenaultPreview';
import { useLocation } from 'react-router-dom';

const EmailSignContainer = ({ download }) => {
	const isMobile = useIsMobile();
	const isConfirm = useSelector((state) => state.picture.confirm);
	const ref = useRef(null);
	const [data, setData] = useState(false);
	const location = useLocation().pathname;
	const { generica, volkswagen, renault, usados } = useIsSign();

	return (
		<>
			{isConfirm ? (
				<Confirm downloadName='Firma' />
			) : (
				<Container
					generica={generica}
					renault={renault}
					volkswagen={volkswagen}
					usados={usados}
					isMobile={isMobile}>
					<InstruccionesPaper name='firma' />
					<DataEdit reference={ref} setData={setData} data={data} />
					{generica ? (
						<SignPreview reference={ref} data={data} empresa='generica' />
					) : volkswagen ? (
						<SignPreview reference={ref} data={data} empresa='rolen' />
					) : usados ? (
						<SignPreview reference={ref} data={data} empresa='usados' />
					) : (
						renault && <SignRenaultPreview reference={ref} data={data} />
					)}
				</Container>
			)}
		</>
	);
};

export default EmailSignContainer;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
	align-items: center;
	justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
	padding: ${({ isMobile, generica, volkswagen, usados, renault }) =>
		isMobile
			? renault
				? '3rem 0 3rem 0'
				: '3rem 0 0 0'
			: generica || volkswagen || usados
			? '6.5rem 0 10rem 0'
			: '3rem 0 4rem 0'};
	flex-wrap: wrap;

	${({ isMobile, generica, volkswagen, usados }) =>
		isMobile && (generica || volkswagen || usados) && 'height: 100vh'};
`;
