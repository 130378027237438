import React, { useEffect, useState } from 'react';
import { useIsCard, useIsMobile } from '../../Hooks/Client';

import { createFile } from '../../Store/Actions/downloadPicture.actions';
import { puestosPerfil } from '../../Utils';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const CardDataEdit = ({ reference, reference2, setData, data }) => {
	const [disabled, setDisabled] = useState(true);
	const isMobile = useIsMobile();
	const dispatch = useDispatch();
	const location = useLocation();
	const {
		tarj_fiat,
		tarj_peugeot,
		tarj_nissan,
		tarj_volkswagen,
		tarj_jeep,
		tarj_multimarca,
		tarj_corporate,
		tarj_usados,
	} = useIsCard();

	useEffect(() => {
		const buttonDisabled = () => {
			const { profileName, position, phone, email } = data;
			if (profileName && position && phone && email) {
				setDisabled(false);
			} else {
				setDisabled(true);
			}
		};
		buttonDisabled();
	}, [data]);

	const handleInputChange = (value, name) => {
		if (name === 'nombre') {
			setData((currentData) => {
				return { ...currentData, profileName: value };
			});
		}
		if (name === 'puesto') {
			setData((currentData) => {
				return { ...currentData, position: value };
			});
		}
		if (name === 'telefono') {
			setData((currentData) => {
				return { ...currentData, phone: value };
			});
		}

		if (name === 'email') {
			setData((currentData) => {
				return { ...currentData, email: value };
			});
		}
	};

	const handleClick = () => {
		dispatch(
			createFile(reference.current, isMobile, location, reference2.current)
		);
	};

	return (
		<Container
			className='dataEdit'
			tarj_fiat={tarj_fiat}
			tarj_peugeot={tarj_peugeot}
			tarj_nissan={tarj_nissan}
			tarj_volkswagen={tarj_volkswagen}
			tarj_jeep={tarj_jeep}
			tarj_multimarca={tarj_multimarca}
			tarj_corporate={tarj_corporate}
			tarj_usados={tarj_usados}
			isMobile={isMobile}>
			<Label htmlFor='nombre'>Nombre y Apellido</Label>
			<TextInput
				className='input'
				isMobile={isMobile}
				type='text'
				maxLength={25}
				name='nombre'
				onChange={(e) => handleInputChange(e.target.value, e.target.name)}
			/>
			<Label htmlFor='puesto'>Puesto</Label>
			<Select
				className='input'
				isMobile={isMobile}
				name='puesto'
				onChange={(e) => handleInputChange(e.target.value, e.target.name)}>
				<Option value=''>Seleccioná tu puesto</Option>
				{
					<>
						{puestosPerfil.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				}
			</Select>
			{
				<>
					<Label htmlFor='telefono'>Teléfono</Label>
					<TextInput
						className='input'
						isMobile={isMobile}
						type='text'
						name='telefono'
						onChange={(e) => handleInputChange(e.target.value, e.target.name)}
					/>{' '}
				</>
			}
			{
				<>
					<Label htmlFor='email'>Email</Label>
					<TextInput
						className='input'
						isMobile={isMobile}
						type='text'
						name='email'
						onChange={(e) => handleInputChange(e.target.value, e.target.name)}
					/>{' '}
				</>
			}
			<Button disabled={disabled} className='input' onClick={handleClick}>
				Generar
			</Button>
		</Container>
	);
};

export default CardDataEdit;

const Container = styled.div`
	background-color: white;
	width: ${({ isMobile }) => (isMobile ? '328px' : '328px')};
	min-height: ${({ isMobile }) => (isMobile ? '450px' : '550px')};
	min-width: ${({ isMobile }) => (isMobile ? null : '328px')};
	height:  ${({ isMobile }) => (isMobile ? '450px' : '550px')};
	border: 1px solid #efefef;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	${({ isMobile }) => isMobile && 'margin: 5rem auto'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${({ isMobile }) => (isMobile ? '2rem 0 ' : null)};

	& > * {
		width: 85%;
	}
`;

const Label = styled.label`
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #353535;
	margin-bottom: 0.25rem;
`;
const TextInput = styled.input`
	width: ${({ isMobile }) => (isMobile ? '70%' : '70%')};
	padding: 0 7.55%;
	margin-bottom: 1rem;
	height: 2.8rem;
	border-radius: 5px;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #575757;
	border: 1px solid #8f8f8f;
`;
const Select = styled.select`
	padding: 11px;
	margin-bottom: 1rem;
	height: 2.9rem;
	border-radius: 5px;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #575757;
	border: 1px solid #8f8f8f;
`;
const Option = styled.option`
	margin-bottom: 1rem;
`;

const Button = styled.button`
	margin-top: 1rem;
	border: none;
	background-color: #007c8f;
	font-size: 1.125rem;
	line-height: 1.7rem;
	padding: 0.65rem 0;
	color: #ffffff;
	border-radius: 5px;
	${({ disabled }) => disabled && 'opacity: 0.5'};
	cursor: pointer;
`;
