import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import DataEdit from '../../Components/DataEdit';

import InstruccionesPaper from '../../Components/InstruccionesPaper';
import { useIsMobile } from '../../Hooks/Client';
import { useSelector } from 'react-redux';
import Confirm from '../../Components/Confirm';
import ProfilePreview from '../../Components/ProfilePreview';

const ProfilePictureContainer = ({ download }) => {
	const isMobile = useIsMobile();
	const isConfirm = useSelector((state) => state.picture.confirm);

	const ref = useRef(null);
	const [data, setData] = useState(false);

	return (
		<>
			{isConfirm ? (
				<Confirm downloadName='Firma' />
			) : (
				<Container isMobile={isMobile}>
					<InstruccionesPaper name='foto de perfil' />
					<DataEdit reference={ref} setData={setData} data={data} />
					<ProfilePreview reference={ref} data={data} />
				</Container>
			)}
		</>
	);
};

export default ProfilePictureContainer;

const Container = styled.div`
	width: 100%;
	${({ isMobile }) => isMobile && 'height: auto'};
	display: flex;
	flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
	align-items: center;
	justify-content: center;
	padding: ${({ isMobile }) => isMobile && '2rem 0 0 0'};
	height: 100%;
	flex-wrap: wrap;
`;
