import './App.css';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import BusinessCardContainer from './Containers/BusinessCardContainer';
import EmailSignContainer from './Containers/EmailSignContainer';
import Logo from './Components/Logo';
import ProfilePictureContainer from './Containers/ProfilePictureContainer';
import React from 'react';
import styled from 'styled-components';
import { useIsMobile } from './Hooks/Client';
import { useSelector } from 'react-redux';

function App() {
	const isMobile = useIsMobile();
	const state = useSelector((state) => state);
	console.log(state.picture);
	return (
		<HashRouter>
			<Logo />
			<Background mobile={isMobile}>
				<Routes>
					<Route path='/' element={<ProfilePictureContainer />} />
					<Route path='/tarjeta_fiat' element={<BusinessCardContainer />} />
					<Route path='/tarjeta_peugeot' element={<BusinessCardContainer />} />
					<Route path='/tarjeta_nissan' element={<BusinessCardContainer />} />
					<Route
						path='/tarjeta_volkswagen'
						element={<BusinessCardContainer />}
					/>
					<Route path='/tarjeta_jeep' element={<BusinessCardContainer />} />
					<Route
						path='/tarjeta_multimarca'
						element={<BusinessCardContainer />}
					/>
					<Route
						path='/tarjeta_corporate'
						element={<BusinessCardContainer />}
					/>
					<Route path='/tarjeta__usados' element={<BusinessCardContainer />} />
					<Route path='/firma_generica' element={<EmailSignContainer />} />
					<Route path='/firma_volkswagen' element={<EmailSignContainer />} />
					<Route path='/firma_renault' element={<EmailSignContainer />} />
					<Route path='/firma_usados' element={<EmailSignContainer />} />
					<Route path='*' element={<Navigate to='./' />} />
				</Routes>
			</Background>
		</HashRouter>
	);
}

export default App;
const Background = styled.div`
	/* width: 100%; */
`;
