import React, { useEffect, useState } from 'react';
import {
	isDisabled,
	puestosMarcas,
	puestosPerfil,
	puestosUsados,
} from '../../Utils';
import { useIsMobile, useIsSign } from '../../Hooks/Client';

import { createFile } from '../../Store/Actions/downloadPicture.actions';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const DataEdit = ({ reference, setData, data }) => {
	const [disabled, setDisabled] = useState(true);
	const isMobile = useIsMobile();
	const dispatch = useDispatch();
	const location = useLocation();
	const { firma, renault, perfil, generica, volkswagen, usados } = useIsSign();

	useEffect(() => {
		const fields = renault
			? 5
			: generica
				? 2
				: volkswagen
					? 2
					: usados && 2;
		setDisabled(isDisabled(data, fields));
		buttonDisabled();
	}, [data, location]);

	const handleImageChange = (e) => {
		setData((currentData) => {
			return { ...currentData, image: e.target.files[0] };
		});
	};

	const handleInputChange = (value, name) => {
		if (name === 'nombre') {
			setData((currentData) => {
				return { ...currentData, profileName: value };
			});
		}
		if (name === 'puesto') {
			setData((currentData) => {
				return { ...currentData, position: value };
			});
		}
		if (name === 'telefono') {
			setData((currentData) => {
				return { ...currentData, phone: value };
			});
		}

		if (name === 'email') {
			setData((currentData) => {
				return { ...currentData, email: value };
			});
		}

		if (name === 'direccion') {
			setData((currentData) => {
				return { ...currentData, adress: value };
			});
		}
	};

	const handleClick = () => {
		console.log(reference.current);
		dispatch(createFile(reference.current, isMobile, location));
	};

	const buttonDisabled = () => {
		const { profileName } = data;
		if (profileName) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	return (
		<Container
			className='dataEdit'
			generica={generica}
			volkswagen={volkswagen}
			usados={usados}
			isMobile={isMobile}>
			{!firma && (
				<FileContainer htmlFor='inputFile'>
					<FileButton>
						<span>+</span>
						<FileInput
							className='fileBut'
							isMobile={isMobile}
							type='file'
							name='imagen'
							id='inputFile'
							onChange={handleImageChange}
						/>
					</FileButton>
					<Title isMobile={isMobile}>
						Subí una foto <br />
						<span>O seleccioná una carpeta</span>
					</Title>
				</FileContainer>
			)}

			<Label htmlFor='nombre'>Nombre y Apellido</Label>
			<TextInput
				className='input'
				isMobile={isMobile}
				type='text'
				maxLength={firma ? 25 : 25}
				name='nombre'
				onChange={(e) => handleInputChange(e.target.value, e.target.name)}
			/>
			<Label htmlFor='puesto'>Puesto</Label>
			<Select
				className='input'
				isMobile={isMobile}
				name='puesto'
				onChange={(e) => handleInputChange(e.target.value, e.target.name)}>
				<Option value=''>Seleccioná tu puesto</Option>
				{perfil ? (
					<>
						{puestosPerfil.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				) : usados ? (
					<>
						{puestosUsados.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				) : generica ? (
					<>
						{puestosPerfil.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				) : volkswagen ? (
					<>
						{puestosPerfil.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				) : (
					<>
						{puestosMarcas.map((puesto, i) => (
							<Option key={i} value={puesto.name}>
								{puesto.name}
							</Option>
						))}
					</>
				)}
			</Select>
			{(perfil || renault) && (
				<>
					<Label htmlFor='telefono'>Teléfono</Label>
					<TextInput
						className='input'
						isMobile={isMobile}
						type='text'
						name='telefono'
						onChange={(e) => handleInputChange(e.target.value, e.target.name)}
					/>{' '}
				</>
			)}
			{renault && (
				<>
					<Label htmlFor='email'>Email</Label>
					<TextInput
						className='input'
						isMobile={isMobile}
						type='text'
						name='email'
						onChange={(e) => handleInputChange(e.target.value, e.target.name)}
					/>{' '}
				</>
			)}
			{renault && (
				<>
					<Label htmlFor='puesto'>Dirección</Label>
					<Select
						className='input'
						isMobile={isMobile}
						name='direccion'
						onChange={(e) => handleInputChange(e.target.value, e.target.name)}>
						<Option value=''>Seleccioná tu dirección</Option>
						<Option value='Av. Santa Ana 6363 esq. Circunvalación | Córdoba Capital'>
							Av. Santa Ana 6363 esq. Circunvalación | Córdoba Capital
						</Option>
						<Option value='Ruta A005 Y Pte. Perón | Río Cuarto | Córdoba'>
							Ruta A005 Y Pte. Perón | Río Cuarto | Córdoba
						</Option>
						<Option value='Av. Perón 3271 | Villa Maria | Córdoba'>
							Av. Perón 3271 | Villa Maria | Córdoba
						</Option>
						<Option value='Av. España 1050 | San Luis Capital | San Luis'>
							Av. España 1050 | San Luis Capital | San Luis
						</Option>
					</Select>
				</>
			)}
			<Button disabled={disabled} className='input' onClick={handleClick}>
				Generar
			</Button>
		</Container>
	);
};

export default DataEdit;

const Container = styled.div`
	background-color: white;
	width: ${({ isMobile }) => (isMobile ? '328px' : '328px')};
	min-height: ${({ generica, volkswagen, usados, isMobile }) =>
		isMobile
			? generica || volkswagen || usados
				? '300px'
				: '550px'
			: generica || volkswagen || usados
				? '400px'
				: '550px'};
	min-width: ${({ isMobile }) => (isMobile ? null : '328px')};
	height: ${({ generica, volkswagen, usados, isMobile }) =>
		isMobile
			? generica || volkswagen || usados
				? '300px'
				: '563px'
			: generica || volkswagen || usados
				? '400px'
				: '550px'};
	border: 1px solid #efefef;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	${({ isMobile }) => isMobile && 'margin: 5rem auto'};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${({ isMobile }) => (isMobile ? '2rem 0 ' : null)};

	& > * {
		width: 85%;
	}
`;
const FileContainer = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 0 1rem 0;
	margin: 0 0 1rem 0;
	border-bottom: 1px solid #cacaca;
	cursor: pointer;
`;

const Title = styled.h3`
	margin: 0;
	line-height: 1.5rem;
	text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
	font-size: 1.5rem;
	span {
		font-size: 15px;
		text-decoration: underline;
		font-weight: 400;
	}
`;
const FileInput = styled.input`
	display: none;
`;

const FileButton = styled.label`
	width: 2.6rem;
	height: 2.6rem;
	border-radius: 2.6rem;
	background-color: black;
	margin-right: 1.25rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	span {
		height: 1.9rem;
		line-height: 1.6rem;
		color: white;
		font-size: 2.5rem;
	}
`;
const Label = styled.label`
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #353535;
	margin-bottom: 0.25rem;
`;
const TextInput = styled.input`
	width: ${({ isMobile }) => (isMobile ? '70%' : '70%')};
	padding: 0 7.55%;
	margin-bottom: 1rem;
	height: 2.8rem;
	border-radius: 5px;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #575757;
	border: 1px solid #8f8f8f;
`;
const Select = styled.select`
	padding: 11px;
	margin-bottom: 1rem;
	height: 2.9rem;
	border-radius: 5px;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #575757;
	border: 1px solid #8f8f8f;
`;
const Option = styled.option`
	margin-bottom: 1rem;
`;

const Button = styled.button`
	margin-top: 1rem;
	border: none;
	background-color: #007c8f;
	font-size: 1.125rem;
	line-height: 1.7rem;
	padding: 0.65rem 0;
	color: #ffffff;
	border-radius: 5px;
	${({ disabled }) => disabled && 'opacity: 0.5'};
	cursor: pointer;
`;
