import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const isClient = () => typeof window === 'object';

const getWindowSize = () => {
	if (!isClient()) return null;

	return { width: window.innerWidth, height: window.innerHeight };
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(getWindowSize());
		};
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowSize;
};

export const useIsMobile = () => {
	const windowSize = useWindowSize();
	return windowSize && windowSize.width < 451;
};

export const useIsSign = () => {
	const location = useLocation().pathname;
	const firma = location.includes('firma') ? true : false;
	const renault = location.includes('renault') ? true : false;
	const generica = location.includes('generica') ? true : false;
	const volkswagen = location.includes('volkswagen') ? true : false;
	const usados = location.includes('usados') ? true : false;
	const perfil = location === '/';
	return { firma, renault, perfil, generica, volkswagen, usados };
};

export const useIsCard = () => {
	const location = useLocation().pathname;
	const tarj_fiat = location.includes('fiat') ? true : false;
	const tarj_peugeot = location.includes('peugeot') ? true : false;
	const tarj_nissan = location.includes('nissan') ? true : false;
	const tarj_volkswagen = location.includes('volkswagen') ? true : false;
	const tarj_jeep = location.includes('jeep') ? true : false;
	const tarj_multimarca = location.includes('multimarca') ? true : false;
	const tarj_corporate = location.includes('corporate') ? true : false;
	const tarj_usados = location.includes('_usados') ? true : false;
	const perfil = location === '/';
	return {  tarj_fiat, tarj_peugeot, tarj_nissan, tarj_volkswagen, tarj_jeep, tarj_multimarca, tarj_corporate, tarj_usados, perfil};
};
