import React, { useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import { NavLink } from 'react-router-dom';
import { cardBrands } from '../../../Utils';
import colors from '../../../Constants/theme.js';
import styled from 'styled-components';
import { useIsMobile } from '../../../Hooks/Client';

const CardSubMenu = (props) => {
	const [autocitySubMenuOpen, setAutocitySubMenuOpen] = useState(false);
	const isMobile = useIsMobile();

	const renderItem = (item) => {
		switch (item.id) {
			case '1':
				return (
					<SubMenuContainer isMobile={isMobile}>
						<SubMenuButton
							isMobile={isMobile}
							className='regular'
							onClick={() => setAutocitySubMenuOpen((prev) => !prev)}>
							{item.name}
							{!autocitySubMenuOpen ? (
								<RiArrowDownSLine size={24} style={{ marginLeft: 5 }} />
							) : (
								<RiArrowUpSLine size={24} style={{ marginLeft: 5 }} />
							)}
						</SubMenuButton>
						<SubMenuOptions isMobile={isMobile}>
							{autocitySubMenuOpen &&
								item.submenu.map((elem, i) => (
									<MenuItem
										isMobile={isMobile}
										className='regular'
										key={elem.id}
										onClick={() => props.onClick()}
										to={`/tarjeta${elem.path}`}>
										{elem.name}
									</MenuItem>
								))}
						</SubMenuOptions>
					</SubMenuContainer>
				);

			default:
				return (
					<MenuItem
						isMobile={isMobile}
						key={item.order}
						to={`/tarjeta${item.path}`}
						onClick={() => props.onClick()}
						className='regular'>
						{item.name}
					</MenuItem>
				);
		}
	};

	return (
		<Container isMobile={isMobile}>
			{cardBrands.map((elem, index) => {
				return renderItem(elem);
			})}
		</Container>
	);
};

export default CardSubMenu;

const Container = styled.div`
	position: ${({ isMobile }) => (isMobile ? 'inline-block' : 'absolute')};
	background-color: ${({ isMobile }) =>
		isMobile ? `${colors.signBlack}` : 'white'};
	width: ${({ isMobile }) => (isMobile ? '100%' : '14%')};
	top: ${({ isMobile }) => (isMobile ? '120px' : '65px')};
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-shadow: ${({ isMobile }) =>
		!isMobile && '0px 4px 8px rgba(0, 0, 0, 0.16)'};
	border-radius: 5px;
	margin: ${({ isMobile }) => isMobile && '0 0 18px 21px'};
	padding: ${({ isMobile }) => (isMobile ? '23px 0 0 0' : '0.5rem 0px')};
`;

const MenuItem = styled(NavLink)`
	text-decoration: none;
	padding: ${({ isMobile }) => (isMobile ? '0 0 0 0' : '0.5rem 1.5rem')};
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${({ isMobile }) => (isMobile ? `${colors.colors.white}` : '#575757')};
	margin: ${({ isMobile }) => isMobile && '28px 0 0 17px'};
`;

const SubMenuContainer = styled.div`
	padding: 0.5rem 1.5rem;
`;
const SubMenuButton = styled.span`
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${({ isMobile }) => (isMobile ? `${colors.colors.white}` : '#575757')};
	display: flex;
	align-items: center;
`;

const SubMenuOptions = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ isMobile }) => isMobile && `${colors.signBlack}`};
`;
